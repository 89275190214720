/* eslint no-unused-vars: 0 */

import { navigate } from "gatsby";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import PropTypes from "prop-types";
import React from "react";

const FormItem = Form.Item;
const { TextArea } = Input;

import "antd/lib/form/style/index.js";
import "antd/lib/input/style/index.js";
import "antd/lib/button/style/index.js";
import { ThemeContext } from "../../layouts";

const Contact = (props) => {
  const [form] = Form.useForm();

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(values) {
    console.log("Received values of form: ", values);
    sendMessage(values);
  }

  function sendMessage(values) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        console.log("Form submission success");
        navigate("/success");
      })
      .catch((error) => {
        console.error("Form submission error:", error);
        this.handleNetworkError();
      });
  }

  function handleNetworkError(e) {
    console.log("submit Error");
  }

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {(theme) => (
          <div className="form">
            <Form
              layout="vertical"
              size="large"
              name="contact"
              form={form}
              onFinish={handleSubmit}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
            >
              <FormItem label="Name" name="name" rules={[{ whitespace: true }]}>
                <Input name="name" />
              </FormItem>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your e-mail address!",
                    whitespace: true,
                    type: "email",
                  },
                ]}
              >
                <Input name="email" />
              </FormItem>
              <FormItem
                label="Message"
                name="message"
                rules={[
                  { required: true, message: "Please enter your message!", whitespace: true },
                ]}
              >
                <TextArea name="message" placeholder="" rows="4" autosize={{ minRows: 6, maxRows: 10 }} />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormItem>
            </Form>

            {/* --- STYLES --- */}
            <style jsx>{`
              @media (prefers-color-scheme: dark) {
                --link-color: ${theme.text.color.primaryGrayWhite};
              }

              @media (prefers-color-scheme: light) {
                --link-color: ${theme.text.color.primary};
              }
           `}</style>
          </div>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

Contact.propTypes = {
  form: PropTypes.object,
};

export default Contact;
